if (typeof(angular) !== 'undefined') {
    (function (angular) {
        'use strict';

        var html = document.getElementsByTagName('html');
        html[0].className += ' ' + 'angular';

        function dataservice($http) {
            return {
                getData: getData,
                saveData: saveData
            };

            function getData(apiURL) {
                return $http.get(apiURL).then(getDataComplete).catch(getDataFailed);
            }

            function saveData(params) {
                var ajaxURL = '/wp-admin/admin-ajax.php';
                params.method = 'POST';
                params.url = ajaxURL;
                return $http(params).then(saveDataComplete).catch(saveDataFailed);
            }

            function getDataComplete(response) {
                return response.data;
            }

            function getDataFailed(error) {
                console.log(error('XHR Failed for getData.' + error.data));
            }

            function saveDataComplete(response) {
                return response.data;
            }

            function saveDataFailed(error) {
                console.log(error('XHR Failed for saveData.' + error.data));
            }
        }

        angular.module('grandwest', ['ngSanitize', 'ngAnimate', 'slickCarousel']);
        angular.module('grandwest').factory('dataservice', dataservice),
            dataservice.$inject = ['$http'];
    })(window.angular);
}