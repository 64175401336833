/* global app */
app.st = (function ($) {
    var st = {
        el: 'a[data-slide-toggle]',

        init: function () {
            st.el = $(st.el);

            st.el.on('click', function (e) {
                st.toggle(e, $(this));
            });
        },

        toggle: function (e, _this) {
            e.preventDefault();
            var target = $('.' + _this.data('slide-toggle'));

            if (target && target.length) {
                _this.hide();
                target.slideToggle(500);
            }
        }
    };

    $(document).on('ready', st.init);
    return st;

})(jQuery);