app.hny = (function ($) {
    'use strict';

    var hny = {
        init: function () {
            $(document).foundation();
            var html = document.getElementsByTagName('html');
            html[0].className += ' ' + 'hny-ui';

            console.log("%cWebsite Design by", "font: 200 16px -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';color:#CCC");
            console.log("%cHoneycomb Creative", "font: 200 28px -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';color:#ffcc05");
            console.log("%chttps://www.honeycombcreative.com", "font: 200 12px -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';color:#CCC");
        },
    };

    $(document).on('ready', hny.init);

    return {};

})(jQuery);