app.mobileNav = (function ($) {
    'use strict';

    var mobileNav = {

        el: $('.navburger'),
        body: $('body'),
        nav: $('.mobile-nav'),

        init: function () {
            mobileNav.el.on('click', mobileNav.toggle);
        },

        toggle: function (e) {
            e.preventDefault();
            mobileNav.body.toggleClass('has-mobile-nav');
        },

        close: function (e) {
            if (mobileNav.body.hasClass('has-mobile-nav')) {
                if ((e.keyCode === 27) || (!($(e.target).is(mobileNav.el) || $(e.target).is(mobileNav.nav)) && (((mobileNav.el.has(e.target).length) || (mobileNav.nav.has(e.target).length)) === 0))) {
                    mobileNav.el.trigger('click');
                }
            }
        }
    };

    $(document).on('ready', mobileNav.init);
    $(document).on('keyup', mobileNav.close);

    return mobileNav;

})(jQuery);