if (typeof(angular) !== 'undefined') {
    (function (angular) {
        'use strict';

        function gwProjectList($attrs, $timeout, $rootScope, dataservice) {
            var t = this,
                source = $attrs.source,
                id = parseInt($attrs.id),
                type = $attrs.type;

            t.type = {
                value: type === 'all' ? undefined : (type === 'LEED' ? type : type.charAt(0).toUpperCase() + type.substr(1).toLowerCase()),
            };

            if (t.type.value === undefined) t.type = undefined;

            t.projectClickHandler = projectClickHandler;
            t.loadProject = loadProject;
            t.resetSearch = resetSearch;
            t.showReset = showReset;
            t.toggleFilters = toggleFilters;
            t.filterSubmit = filterSubmit;
            t.filters = undefined;
            t.searchFilters = undefined;
            t.filterOpen = false;
            t.isProjectLoaded = true;
            t.renderCallback = renderCallback;
            t.perPage = '10';
            t.currentProject = '';
            t.pagination = {
                'current': 1,
            };

            loadProjects(source);
            angular.element(document).on('keydown', keyHandler);
            window.addEventListener('popstate', populateState);

            function renderCallback() {
                $timeout(function () {
                    t.currentIndex = getProjectIndex();
                    scrollTo(t.currentIndex);
                }, 1);
            }

            function scrollTo(index) {
                angular.element('.project-list__items').stop().animate({
                    scrollTop: angular.element('.project-list__items').scrollTop() - angular.element('.project-list__items').offset().top + angular.element('.project-list__item').eq(index).offset().top
                }, 750);
            }

            function keyHandler($event) {
                if ($event.keyCode === 40 || $event.keyCode === 38) {
                    $event.preventDefault();

                    var index = t.currentIndex;

                    if (angular.isNumber(index)) {
                        switch ($event.keyCode) {
                            case 40 :
                                index += 1;
                                break;
                            case 38 :
                                index -= 1;
                                break;
                        }

                        var item = t.filteredItems[index];

                        if (angular.isObject(item)) {
                            t.currentIndex = index;
                            scrollTo(index);
                            historyState(item.id, item.permalink, item.name);
                            loadProject(t.filteredItems[index].id);
                        }
                    }
                }
            }

            function getProjectIndex() {
                if (t.filteredItems) {
                    var project = t.filteredItems.filter(function (item) {
                        return item.id === t.currentProject;
                    });

                    if (!angular.isObject(project)) return;

                    var index = parseInt(t.filteredItems.indexOf(project[0]));

                    return index;
                }
            }

            function filterSubmit($event) {
                $event.currentTarget[0].blur();
            }

            function toggleFilters() {
                t.filterOpen = !t.filterOpen;
            }

            function emitProject() {
                if (t.project)
                    $rootScope.$emit('project', t.project);
            }

            function emitStatus() {
                $rootScope.$emit('isProjectLoaded', t.isProjectLoaded);
            }

            function projectClickHandler(item, $event) {
                $event.preventDefault();

                t.toggleFilters();

                if (t.currentProject !== item.id) {
                    //scrollTo(t.filteredItems.indexOf(item));
                    historyState(item.id, item.permalink, item.name);
                    loadProject(item.id);
                }
            }

            function showReset() {
                if (!t.filters) return;
                if (t.filters.keywords || t.filters.types || t.filters.city || t.filters.leed) return true;
            }

            function resetSearch($event) {
                $event.preventDefault();
                t.filters = undefined;
            }

            function historyState(id, permalink, name) {
                document.querySelector('title').innerHTML = name + ' - Grand West Electric Ltd.';
                history.pushState(id, name, permalink);
            }

            function populateState(event) {
                var id = angular.isNumber(event.state) ? event.state : $attrs.id;
                loadProject(id);
            }

            function loadProject(id) {
                t.isProjectLoaded = false;
                emitStatus();
                t.currentProject = id;
                dataservice.getData(source + id).then(function (data) {
                    $timeout(function () {
                        t.project = data;
                        t.isProjectLoaded = true;
                        emitStatus();
                        emitProject();
                        t.currentIndex = getProjectIndex();
                    }, 250)
                });
            }

            function loadProjects(apiURL) {
                t.items = undefined;
                t.isLoading = true;

                loadProject(id);

                dataservice.getData(apiURL).then(function (data) {
                    var i = 0,
                        items = data.items,
                        arrLen = items.length;

                    for (; i < arrLen; i++) {
                        items[i].keywords = items[i].name + ' ' + items[i].city + ' ' + items[i].address + ' ' + items[i].details;
                    }

                    t.items = items;

                    var leed = [];
                    angular.forEach(data.leed, function (value, key) {
                        if (key.indexOf('Certified') === -1) leed.push(key);
                    });

                    t.searchData = {
                        'types': data.types,
                        'cities': data.cities,
                        'leed': leed,
                    };
                    t.leed = data.leed;

                    t.isLoading = false;
                });
            }
        }

        angular.module('grandwest').component('gwProjectList', {
            bindings: {},
            template: '<div class="gw-project-list__wrapper" data-ng-class="{\'is-open\': vm.filterOpen}">' +
            '<button class="gw-project-list__toggle" data-ng-click="vm.toggleFilters()">' +
            '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path fill="#FFFFFF" d="M 7.21875 5.78125 L 5.78125 7.21875 L 14.5625 16 L 5.78125 24.78125 L 7.21875 26.21875 L 16 17.4375 L 24.78125 26.21875 L 26.21875 24.78125 L 17.4375 16 L 26.21875 7.21875 L 24.78125 5.78125 L 16 14.5625 L 7.21875 5.78125 z"></path></svg>' +
            '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" enable-background="new 0 0 50 50"><path fill="#FFFFFF" d="M 21 3 C 11.6 3 4 10.6 4 20 C 4 29.4 11.6 37 21 37 C 24.354126 37 27.461484 36.015541 30.09375 34.34375 L 42.375 46.625 L 46.625 42.375 L 34.5 30.28125 C 36.680155 27.423093 38 23.878362 38 20 C 38 10.6 30.4 3 21 3 z M 21 7 C 28.2 7 34 12.8 34 20 C 34 27.2 28.2 33 21 33 C 13.8 33 8 27.2 8 20 C 8 12.8 13.8 7 21 7 z"></path></svg>' +
            '</button>' +
            '<form class="project-search" data-ng-if="vm.searchData" data-ng-submit="vm.filterSubmit($event)" >' +
            '<div class="project-search__filters">' +
            '<div class="project-search__filter project-search__filter--wide"><label>Keywords' +
                      ' <input type="search" data-ng-model="vm.filters.keywords" data-ng-model-options="{debounce: 250}" placeholder="Search projects by name or location" /></label></div>' +
            '<div class="project-search__filter"><label>Project Type<p><select data-ng-options="type as type.name for type in vm.searchData.types track by type.value" data-ng-init="vm.filters.types = vm.type" data-ng-model="vm.filters.types"><option value="">All</option></select></label></div>' +
            '<div class="project-search__filter"><label>City<p><select data-ng-options="::city' +
                      ' for city in vm.searchData.cities" data-ng-model="vm.filters.city"><option value="">All</option></select></label></div>' +
            '</div>' +
            '<a class="project-search__reset-link" href="#" data-ng-if="vm.showReset()" data-ng-click="vm.resetSearch($event)">&times; Reset Search</a>' +
            '<input type="submit" name="submit" value="Search" style="position: absolute; height: 0px; width: 0px; border: none; padding: 0px;" hidefocus="true" tabindex="-1"/>' +
            '</form>' +
            '<div class="project-list">' +
            '<div data-ng-if="vm.isLoading" class="loader"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50"><path d="M37.28225983 10.58815267c-7.90404656-6.63228255-19.6882839-5.6020052-26.32120924 2.30280742-6.63228257 7.90404656-5.60123915 19.68892668 2.3028074 26.32120923l2.61486-3.1162688c-6.1827447-5.1879388-6.9899802-14.4065697-1.8013986-20.59008044 5.18858157-6.18351076 14.4065697-6.98998022 20.59008044-1.80139863l2.61486-3.1162688z"><animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="0.6s" repeatCount="indefinite"/></path></svg></div>' +
            '<div class="project-list__items">' +
            '<div class="project-list__item" data-ng-repeat="item in vm.filteredItems = (vm.items | filter: { keywords: vm.filters.keywords || undefined, types: vm.filters.types.value || undefined, city: vm.filters.city || undefined, leed: vm.filters.leed || undefined })" ng-repeat-callback last="$last">' +
            '<div class="project" data-ng-class="{\'project--has-logo\': item.logo}">' +
            '<a href="#" data-ng-href="{{::item.permalink}}" data-ng-click="vm.projectClickHandler(item, $event)" data-ng-class="{\'is-active\': vm.currentProject === item.id, \'is-loaded\': vm.isProjectLoaded}">' +
            '<div class="project__loader"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50"><path d="M37.28225983 10.58815267c-7.90404656-6.63228255-19.6882839-5.6020052-26.32120924 2.30280742-6.63228257 7.90404656-5.60123915 19.68892668 2.3028074 26.32120923l2.61486-3.1162688c-6.1827447-5.1879388-6.9899802-14.4065697-1.8013986-20.59008044 5.18858157-6.18351076 14.4065697-6.98998022 20.59008044-1.80139863l2.61486-3.1162688z"><animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="0.6s" repeatCount="indefinite"/></path></svg></div>' +
            '<figure class="project-list__photo" data-ng-style="{\'background-image\' : \'url({{::item.thumbnail}})\'}"></figure>' +
            '<div class="project-list__details">' +
            '<h3 class="project-list__name" data-ng-bind-html="::item.name"></h3>' +
            '<p class="project-list__info" data-ng-if="::(item.types || item.city)">{{::item.types[0]}} <span data-ng-if="::(item.types && item.city)">/</span> {{::item.city}}</p>' +
            '</div>' +
            '<div class="project-list__leed" data-ng-if="::item.leed"><img src="#" data-ng-src="{{::vm.leed[item.leed].icon}}" alt="{{::item.leed}}" /></div>' +
            '</a>' +
            '</div>' +
            '</div>' +
            '<div class="project-list__item project-list__item--no-results" data-ng-if="vm.items">' +
            '<div class="project"><p class="lead">No projects found that match the selected criteria. Please try again.</p></div>' +
            '</div>' +
            '</div>' +
            '</div>' +
            '</div>',
            controller: gwProjectList,
            controllerAs: 'vm',
        }).directive('ngRepeatCallback', function () {
            return {
                restrict: 'A',
                scope: {
                    last: '='
                },
                link: function ($scope) {
                    $scope.$watch('last', function () {
                        if ($scope.last === true) {
                            $scope.$parent.vm.renderCallback();
                        }
                    });
                }
            }
        }),
            gwProjectList.$inject = ['$attrs', '$timeout', '$rootScope', 'dataservice'];
    })(window.angular);
}
