app.subNav = (function ($) {
    'use strict';

    var subNav = {
        init: function () {
            $('.sub-nav__submenu').find('.has-sub-menu > a').on('click', subNav.click);
        },

        click: function (e) {
            e.preventDefault();

            $(this).parent().toggleClass('is-submenu-visible');
        },
    };

    $(document).on('ready', subNav.init);

    return subNav;
})(jQuery);