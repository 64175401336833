app.sliders = (function ($) {
    'use strict';

    var sliders = {
        init: function () {
            $('.hero-slider').slick({
                slidesToShow: $('.hero-slider').data('slick-show'),
                slidesToScroll: $('.hero-slider').data('slick-scroll'),
                speed: 500,
                infinite: true,
                fade: true,
                autoplay: true,
                dots: true,
                arrows: false,
                draggable: true,
            });

            $('.recent-projects').slick({
                slidesToShow: $('.recent-projects').data('slick-show'),
                slidesToScroll: $('.recent-projects').data('slick-scroll'),
                speed: 500,
                infinite: true,
                prevArrow: '<button type="button" title="Previous" class="slick-prev"><span></span></button>',
                nextArrow: '<button type="button" title="Next" class="slick-next"><span></span></button>',
                draggable: false,
                fade: false,
                responsive: [{
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }, {
                    breakpoint: 750,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }, {
                    breakpoint: 500,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        fade: true,
                    }
                }]
            });

            $('.events__gallery-slider').slick({
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: true,
                fade: false,
                asNavFor: '.events__gallery-nav',
                prevArrow: '<button type=button" title="Previous" class="slick-prev"></button>',
                nextArrow: '<button type="button" title="Next" class="slick-next"></button>',
                adaptiveHeight: true
            });

            $('.events__gallery-nav').slick({
                slidesToShow: 5,
                slidesToScroll: 1,
                asNavFor: '.events__gallery-slider',
                dots: false,
                arrows: false,
                centerMode: true,
                focusOnSelect: true,
                fade: false,
                responsive: [
                    {
                        breakpoint: 1024,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 3
                        }
                    }
                ]
            });

            $('.tabs').on('change.zf.tabs', function (event, tab) {
                $('.events__gallery-slider, .events__gallery-nav').slick('setPosition');
            });

            $('.accordion').on('down.zf.accordion', function (event, tab) {
                $('.events__gallery-slider').slick('setPosition');
            });
        },
    };

    $(document).on('ready', sliders.init);

    return sliders;

})(jQuery);