app.nav = (function ($) {
    'use strict';

    var nav = {
        el: '.has-sub-menu > a',

        init: function () {
            nav.el = $(nav.el);
        }
    };

    $(document).on('ready', nav.init);

    return {};

})(jQuery);