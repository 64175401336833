app.wpcf7 = (function ($) {
    'use strict';

    var wpcf7 = {
        init: function () {
            $('.wpcf7-submit').click(wpcf7.submit);
            $('.wpcf7 input, .wpcf7 textarea').focus(wpcf7.focus);
            $('.wpcf7 input, .wpcf7 textarea').blur(wpcf7.blur);
            $('.wpcf7 input, .wpcf7 textarea').on('input', wpcf7.input);
        },

        submit: function () {
            var btn = $(this),
                text = btn.html(),
                form = $(this).parents('.wpcf7:first');

            btn.html('Sending... <i class="fa fa-spinner fa-spin"></i>');

            form.on('wpcf7:invalid', function () {
                btn.html(text);
            });

            form.on('wpcf7:mailsent', function () {
                form.find(':input').prop('disabled', 'disabled');
                btn.html('<i class="fa fa-check-circle"></i> Sent. Thanks!');
            });
        },

        focus: function () {
            $(this).closest('.hny-form__row').addClass('input-focused');
        },

        blur: function () {
            $(this).closest('.hny-form__row').removeClass('input-focused');

            if ($(this).val() === '') {
                $(this).closest('.hny-form__row').removeClass('has-value');
            }
        },

        input: function () {
            $(this).closest('.hny-form__row').addClass('has-value');
        },
    };

    $(document).on('ready', wpcf7.init);

    return wpcf7;
})(jQuery);