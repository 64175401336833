app.searchToggle = (function ($) {
    'use strict';

    var searchToggle = {
        el: $('.js-search-toggle > a'),
        li: $('.js-search-toggle'),
        search: $('.site-search'),
        body: $('body'),

        init: function () {
            searchToggle.el.on('click', searchToggle.toggle);
        },

        toggle: function (e) {
            e.preventDefault();

            if (searchToggle.body.hasClass('has-site-search')) {
                searchToggle.body.removeClass('has-site-search').find('.site-search input[type="text"]').blur();
            }
            else {
                searchToggle.body.addClass('has-site-search').find('.site-search input[type="text"]').focus();
            }
        },

        handler: function (e) {
            if (searchToggle.body.hasClass('has-site-search')) {
                if ((e.keyCode === 27) || (!($(e.target).is(searchToggle.search) || $(e.target).is(searchToggle.el)) && (((searchToggle.search.has(e.target).length) || (searchToggle.el.has(e.target).length)) === 0))) {
                    searchToggle.el.trigger('click');
                }
            }
        }

    };

    $(document).on('ready', searchToggle.init);
    $(document).on('click keyup', searchToggle.handler);

    return searchToggle;

})(jQuery);