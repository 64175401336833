app.stickyFooter = (function ($) {
    'use strict';

    var stickyFooter = {

        el: $('.footer'),

        init: function () {
            var pos = stickyFooter.el.position(),
                height = $(window).height();

            height = height - pos.top;
            height = height - stickyFooter.el.height() - 1;

            if (height > 0) {
                stickyFooter.el.css({
                    'margin-top': height + 'px'
                });
            }
        },

    };

    $(window).on('load resize orientationChange', stickyFooter.init);

    return stickyFooter;
})(jQuery);