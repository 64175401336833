app.magicLine = (function ($) {
    'use strict';

    var magicLine = {

        nav: $('.header__nav-items'),
        links: $('.header__nav-items > li').not('.header__nav-line'),
        active: ($('.header__nav-items > .header__nav-item.is-active > a').length) ? $('.header__nav-items > .header__nav-item.is-active > a') : $('.header__nav-item:first-child > a'),

        init: function () {
            magicLine.origWidth = 100;

            magicLine.nav.append('<li class="header__nav-line"></li>');
            magicLine.line = $('.header__nav-line');

            magicLine.position();
            magicLine.hover();
        },

        position: function () {
            var newWidth = magicLine.active.parent().width();
            magicLine.origLeft = magicLine.active.position().left;
            magicLine.origRatio = newWidth / magicLine.line.width();

            magicLine.line.css('transform', 'translateX(' + magicLine.origLeft + 'px) scaleX(' + magicLine.origRatio + ')');
        },

        hover: function () {
            magicLine.links.hover(function () {
                magicLine.mouseIn($(this));
            }, function () {
                magicLine.mouseOut();
            });
        },

        mouseIn: function (_this) {
            var $el = _this.find('>a'),
                leftPos = $el.position().left,
                newWidth = $el.parent().width(),
                ratio = newWidth / magicLine.origWidth;

            magicLine.line.css('transform', 'translateX(' + leftPos + 'px) scaleX(' + ratio + ')');
        },

        mouseOut: function () {
            magicLine.line.css('transform', 'translateX(' + magicLine.origLeft + 'px) scaleX(' + magicLine.origRatio + ')');
        },

    };

    $(window).on('load', magicLine.init);
    $(window).on('resize orientationChange', magicLine.position);

    return magicLine;

})(jQuery);