if (typeof(angular) !== 'undefined') {
    (function (angular) {
        'use strict';

        function gwList($attrs, $timeout, dataservice) {
            var t = this,
                apiURL = '',
                apiBase = $attrs.source;
            t.setYear = setYear;
            t.isActiveYear = isActiveYear;
            getData(apiBase);

            function getData(apiURL) {
                t.items = undefined;
                t.isLoading = true;
                dataservice.getData(apiURL).then(function (data) {
                    if (!t.years) t.years = data.years;
                    if (!t.activeYear) {
                        if (data['info']) t.activeYear = data['info'].active_year;
                    }
                    $timeout(function () {
                        t.items = data.items;
                        t.isLoading = false;
                    }, 250);
                });
            }

            function setYear(year, $event) {
                $event.preventDefault();
                if (year === t.activeYear) return;

                t.activeYear = year;
                apiURL = apiBase + year;
                getData(apiURL);
            }

            function isActiveYear(year) {
                if (t.activeYear === year) return true;
            }
        }

        angular.module('grandwest').component('gwList', {
            bindings: {},
            template: '<ul class="block-links" data-ng-if="vm.years"><li class="block-links__item" data-ng-repeat="year in vm.years"><a href="#" class="block-links__link" data-ng-class="{\'is-active\': vm.isActiveYear(year)}" data-ng-click="vm.setYear(year, $event)" data-ng-disabled="vm.isLoading">{{year}}</a></li></ul>' +
            '<div class="gw-list">' +
            '<div data-ng-show="vm.isLoading" class="loader"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50"><path d="M37.28225983 10.58815267c-7.90404656-6.63228255-19.6882839-5.6020052-26.32120924 2.30280742-6.63228257 7.90404656-5.60123915 19.68892668 2.3028074 26.32120923l2.61486-3.1162688c-6.1827447-5.1879388-6.9899802-14.4065697-1.8013986-20.59008044 5.18858157-6.18351076 14.4065697-6.98998022 20.59008044-1.80139863l2.61486-3.1162688z"><animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="0.6s" repeatCount="indefinite"/></path></svg></div>' +
            '<div class="gw-list__item ng-animate--fadeUp" data-ng-repeat="item in vm.items">' +
            '<article class="post">' +
            '<time class="post__date" data-ng-if="item.altDate" datetime="{{item.altDate}}">{{item.date}}</time>' +
            '<h3 class="post__title"><a href="#" data-ng-href="{{item.permalink}}" data-ng-bind-html="item.title"></a></h3>' +
            '<div class="post__content"><div class="wysiwyg" data-ng-bind-html="item.content"></div></div>' +
            '</article>' +
            '</div>' +
            '</div>',
            controller: gwList,
            controllerAs: 'vm',
        }),
            gwList.$inject = ['$attrs', '$timeout', 'dataservice'];
    })(window.angular);
}