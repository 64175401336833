if (typeof(angular) !== 'undefined') {
    (function (angular) {
        'use strict';

        function gwProjectView($timeout, $rootScope) {
            var t = this;
            t.slickConfig = {
                enabled: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: false,
                fade: true,
                autoplay: true,
                draggable: true,
                autoplaySpeed: 3000,
            };
            $rootScope.$on('project', loadProject);
            $rootScope.$on('isProjectLoaded', isProjectLoaded);

            function isProjectLoaded(event, status) {
                t.isProjectLoaded = status;
            }

            function loadProject(event, project) {
                t.project = project;
                initGallery();
            }

            function initGallery() {
                t.slickLoaded = t.gallery = false;

                if (!t.project.photos) return;

                if (t.project.photos.length > 1) {
                    t.photos = t.project.photos;
                    t.gallery = true;
                    $timeout(function () {
                        t.slickLoaded = true
                    }, 1);
                }
                else {
                    t.photos = t.project.photos[0];
                }
            }
        }

        angular.module('grandwest').component('gwProjectView', {
            bindings: {},
            template: '<div data-ng-if="!vm.isProjectLoaded" class="loader"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50"><path d="M37.28225983 10.58815267c-7.90404656-6.63228255-19.6882839-5.6020052-26.32120924 2.30280742-6.63228257 7.90404656-5.60123915 19.68892668 2.3028074 26.32120923l2.61486-3.1162688c-6.1827447-5.1879388-6.9899802-14.4065697-1.8013986-20.59008044 5.18858157-6.18351076 14.4065697-6.98998022 20.59008044-1.80139863l2.61486-3.1162688z"><animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="0.6s" repeatCount="indefinite"/></path></svg></div>' +
            '<div class="project-view" data-ng-class="{\'is-loading\': !vm.isProjectLoaded}">' +
            '<div class="project-view__inner">' +
            '<div class="project-view__photos" data-ng-if="vm.project.photos">' +
            '<div data-ng-if="vm.gallery">' +
            '<slick class="project-view__slider" settings="vm.slickConfig" data-ng-if="vm.slickLoaded"><div data-ng-repeat="photo in vm.photos" class="project-view__hero-item"><figure style="background-image: url(\'{{photo}}\');"></figure></div></slick>' +
            '<button type="button" title="Previous" class="slick-prev"><span></span></button>' +
            '<button type="button" title="Next" class="slick-next"><span></span></button>' +
            '</div>' +
            '<div data-ng-if="!vm.gallery && vm.photos"><figure style="background-image: url(\'{{vm.photos}}\');"></figure></div>' +
            '</div>' +
            '<div class="project-view__content">' +
            '<div data-ng-if="vm.project.logo" class="project-view__logo">' +
            '<img src="#" data-ng-src="{{vm.project.logo}}" alt="{{vm.project.name}}">' +
            '</div>' +
            '<header><h2 data-ng-bind-html="vm.project.name"></h2><h4 data-ng-if="vm.project.types">{{vm.project.types.join(\', \')}} <span data-ng-if="vm.project.subtype">{{vm.project.subtype}}</span></h4></header>' +
            '<div class="project-view__address">{{vm.project.address}}</div>' +
            '<ul class="project-view__meta">' +
            '<li class="project-view__meta-item" data-ng-if="vm.project.developer">' +
            '<span data-ng-show="!vm.project.developer.url"><strong>Developer:</strong> {{vm.project.developer.name}}</span>' +
            '<span data-ng-show="!!vm.project.developer.url"><strong>Developer:</strong> <a href="#" data-ng-href="{{vm.project.developer.url}}" target="_blank">{{vm.project.developer.name}}</a></span>' +
            '</li>' +
            '<li class="project-view__meta-item" data-ng-if="vm.project.contractor">' +
            '<span data-ng-show="!vm.project.contractor.url"><strong>Contractor:</strong> {{vm.project.contractor.name}}</span>' +
            '<span data-ng-show="!!vm.project.contractor.url"><strong>Contractor:</strong> <a href="#" data-ng-href="{{vm.project.contractor.url}}" target="_blank">{{vm.project.contractor.name}}</a></span>' +
            '</li>' +
            '<li class="project-view__meta-item project-view__meta-item--no-bullet" data-ng-if="vm.project.details"><span data-ng-bind-html="vm.project.details"></span></li>' +
            '<li class="project-view__meta-item project-view__meta-item--no-bullet" data-ng-if="vm.project.leed"><div class="project-view__leed"><span><img src="#" data-ng-src="{{vm.project.leed.icon}}" alt="{{vm.project.leed.level}}" /> LEED {{vm.project.leed.level}}</span></li>' +
            '<li class="project-view__meta-item project-view__meta-item--no-bullet" data-ng-if="vm.project.website"><span><a href="#" data-ng-href="{{vm.project.website}}" data-ng-attr-title="{{vm.project.title}}" target="_blank">Visit Project Website</a></span></li>' +
            '</ul>' +
            '</div>' +
            '</div>' +
            '</div>',
            controller: gwProjectView,
            controllerAs: 'vm',
        }),
            gwProjectView.$inject = ['$timeout', '$rootScope'];
    })(window.angular);
}