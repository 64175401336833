app.flexVideo = (function($) {
    'use strict';

    var flexVideo = {
        init: function() {
            $('iframe[src*="youtube.com"]').wrap("<div class='flex-video widescreen'/>");
            $('iframe[src*="vimeo.com"]').wrap("<div class='flex-video widescreen vimeo'/>");
        }
    };

    $(document).on('ready', flexVideo.init);

    return flexVideo;

})(jQuery);