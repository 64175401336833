app.scrollTarget = (function ($) {
    var scrollTarget = {
        el: 'a[data-scroll-target]',

        init: function () {
            scrollTarget.el = $(scrollTarget.el);

            scrollTarget.el.on('click', function (e) {
                scrollTarget.scroll(e, $(this));
                scrollTarget.updateHistory(e);
            });
        },

        updateHistory: function (e) {
            var title = document.getElementsByTagName('title')[0].innerHTML;
            history.pushState(null, title, e.target.href);
        },

        scroll: function (e, _this) {
            e.preventDefault();
            var target = $('#' + _this.data('scroll-target'));

            if (target && target.length) {
                var offset = target.offset().top;

                $('body, html').animate({
                        scrollTop: offset,
                        easing: 'easeInOutExpo',
                    }, 500
                );
            }
        }
    };

    $(document).on('ready', scrollTarget.init);
    return scrollTarget;

})(jQuery);