app.gmaps = (function ($) {
    'use strict';

    var gmaps = {
        // Get the HTML DOM element that will contain your map
        // We are using a div with id="map" seen below in the <body>

        init: function () {
            var mapElement = document.getElementById('map'),
                lat = mapElement.getAttribute('data-lat'),
                long = mapElement.getAttribute('data-long');

            // Basic options for a simple Google Map
            // For more options see: https://developers.google.com/maps/documentation/javascript/reference#MapOptions
            var mapOptions = {
                scrollwheel: false,
                zoomControl: true,
                zoomControlOptions: {
                    position: google.maps.ControlPosition.LEFT_CENTER
                },

                // How zoomed in you want the map to start at (always required)
                zoom: 15,

                // The latitude and longitude to center the map (always required)
                center: new google.maps.LatLng(lat, long),

                // How you would like to style the map.
                // This is where you would paste any style found on Snazzy Maps.
                styles: [{"stylers": [{"saturation": -100}, {"gamma": 1}]}, {
                    "elementType": "labels.text.stroke",
                    "stylers": [{"visibility": "off"}]
                }, {
                    "featureType": "poi",
                    "stylers": [{"visibility": "off"}]
                }, {
                    "featureType": "road",
                    "elementType": "geometry",
                    "stylers": [{"visibility": "simplified"}]
                }, {
                    "featureType": "water",
                    "stylers": [{"visibility": "on"}, {"saturation": 50}, {"gamma": 0}, {"hue": "#50a5d1"}]
                }, {
                    "featureType": "administrative.neighborhood",
                    "elementType": "labels.text.fill",
                    "stylers": [{"color": "#333333"}]
                }, {
                    "featureType": "road.local",
                    "elementType": "labels.text",
                    "stylers": [{"weight": 0.5}, {"color": "#333333"}]
                }, {
                    "featureType": "transit.station",
                    "elementType": "labels.icon",
                    "stylers": [{"gamma": 1}, {"saturation": 50}]
                }]
            };

            // Create the Google Map using our element and options defined above
            var map = new google.maps.Map(mapElement, mapOptions);

            // Let's also add a marker while we're at it
            var marker = new google.maps.Marker({
                position: new google.maps.LatLng(lat, long),
                map: map,
                title: 'Grand West Electric Ltd.',
                icon: gmaps.pinSymbol('#9C1F2E'),
            });
        },

        pinSymbol: function (color) {
            return {
                path: 'M 0,0 C -2,-20 -10,-22 -10,-30 A 10,10 0 1,1 10,-30 C 10,-22 2,-20 0,0 z M -2,-30 a 2,2 0 1,1 4,0 2,2 0 1,1 -4,0',
                fillColor: color,
                fillOpacity: 1,
                strokeColor: '#000',
                strokeWeight: 0,
                scale: 2,
            };
        },
    };

    if ($('body').hasClass('page-id-9')) {
        google.maps.event.addDomListener(window, 'load', gmaps.init);
    }

    return gmaps;

})(jQuery);